import React, { Component } from "react"
import { ResponsiveImage, RenderOnMount } from "./helpers"
import "../components/styles/heroslider.scss"
import { BrowserView, MobileView } from "react-device-detect"

export default class HeroSlider extends Component {
  constructor(props) {
    super(props)
    this.state = { activeIndex: 0 }
    this.openIndex = this.openIndex.bind(this)
  }

  openIndex = index => {
    this.setState({ activeIndex: index })
  }

  render() {
    const { sliders = [] } = this.props
    const { activeIndex } = this.state
    return (
      <div className={"heroslider-container"}>
        <div className={"slideroptions-container"}>
          {sliders.map(({ image, activeImage, title, description }, index) => (
            <div
              key={index}
              className={"slideroption"}
              onClick={this.openIndex.bind(this, index)}
            >
              {index === activeIndex ? (
                <ResponsiveImage
                  className={"slideroption-image"}
                  image={activeImage}
                />
              ) : (
                <ResponsiveImage
                  className={"slideroption-image"}
                  image={image}
                />
              )}

              <div className={"info"}>
                <h3>{title}</h3>
                <p>{description}</p>
                <div
                  className={`active-bar ${
                    index === activeIndex ? "active" : ""
                  }`}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={"selected-item"}>
          <RenderOnMount>
            <MobileView>
              <video
                key={activeIndex}
                playsInline={true}
                autoPlay={true}
                muted={true}
                onEnded={() => {
                  this.setState({
                    activeIndex: (activeIndex + 1) % sliders.length,
                  })
                }}
                // style={{ height: "100%" }}
              >
                <source
                  src={sliders[activeIndex].video.mobileURL}
                  type="video/mp4"
                ></source>
              </video>
            </MobileView>
            <BrowserView>
              <video
                key={activeIndex}
                playsInline={true}
                autoPlay={true}
                muted={true}
                onEnded={() => {
                  setTimeout(() => {
                    this.setState({
                      activeIndex: (activeIndex + 1) % sliders.length,
                    })
                  }, 300)
                }}
                // style={{ height: "100%" }}
              >
                <source
                  src={sliders[activeIndex].video.url}
                  type="video/mp4"
                ></source>
              </video>
            </BrowserView>
          </RenderOnMount>
        </div>
      </div>
    )
  }
}
