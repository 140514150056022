import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import { MobileView, BrowserView } from "react-device-detect"
import { RenderOnMount, ResponsiveImage } from "../components/helpers"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../../src/components/styles/landing.scss"
import HeroSlider from "../components/HeroSlider"
import { Toast } from "../components/toast"

import MasonVid from "../components/icons/pricing/mvid.gif"

const IndexPage = () => {
  const { site } = useStaticQuery(graphql`
    query LandingPageQuery {
      site {
        siteMetadata {
          heroSlider {
            title
            description
            image {
              url
              mobileURL
            }
            activeImage {
              url
              mobileURL
            }
            video {
              url
              mobileURL
            }
          }
          sections {
            title
            description
            subdescription
            images {
              url
              mobileURL
            }
            videos {
              url
              mobileURL
            }
            cta {
              text
            }
          }
          testimonials {
            name
            message
            logo {
              url
              alt
            }
            dp {
              url
            }
          }
          integrations {
            name
            logo {
              url
            }
          }
        }
      }
    }
  `)
  const { siteMetadata: data } = site

  const [emailId, setEmailId] = useState()
  const [isToastVisible, setIsToastVisible] = useState(false)

  const registerInterest = emailId => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")
    var raw = JSON.stringify({ emailId: emailId })
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    }
    fetch(
      "https://us-central1-kubric-website.cloudfunctions.net/addVisitor",
      requestOptions
    )
  }
  const [mason, setMason] = useState(true)
  // useEffect(() => {
  //     setMason(true)
  // }, [])
  return (
    <Layout>
      {mason && (
        <div className="m_wrap">
          <div className="m_pop">
            <img src={MasonVid} alt="vid"/>
            <div className="stuff">
              <h2>Kubric is now Mason!</h2>
              <br/>
              <span>We are rebranding to Mason.</span>
              <a href='https://getmason.io/' className="take">Show me Mason</a>
            </div>
          </div>
        </div>
      )}
      <SEO title="Kubric" />
      {isToastVisible && (
        <Toast
          show={isToastVisible}
          title={"Awesome 🥳"}
          imgSrc={
            "https://lh3.googleusercontent.com/DYBA6tcbshkGRY28WhxSH3sKkbYjLvTnohQiSFonzE2JNktk6xzrC5x4lrEnXC00I3eEC_IhEnUWkG7qivU9Yw"
          }
          description={"Our team will soon connect with you"}
          onHide={setIsToastVisible.bind(undefined, false)}
        ></Toast>
      )}
      <a
        id={"remote-cta"}
        className={"attention-grabber"}
        href="https://getmason.io/"
        target="_blank"
      >
        <span className={"bold"}>NEW!</span>
        <span className={"callout"}>We’re rebranding to Mason.</span>
        <span className={"callout button"}>Check us here</span>
      </a>
      <section className={"topSection inset jumbojumbo"}>
        <h1>{data.sections[0].title}</h1>
        <form id="form1">
          <label>{data.sections[0].description}</label>
          <label>{data.sections[0].subdescription}</label>
          <div className={"cta"}>
            <input
              type="email"
              placeholder={"Your email"}
              value={emailId}
              onInput={e => {
                setEmailId(e.target.value)
              }}
            ></input>
            <button
              id={"hero-capture-button"}
              form="form1"
              onClick={e => {
                e.preventDefault()
                if (emailId) {
                  registerInterest(emailId)
                  setEmailId("")
                  setIsToastVisible(true)
                }
              }}
            >
              {data.sections[0].cta.text}
            </button>
          </div>
        </form>
        <div className={"slider-container"}>
          <HeroSlider sliders={data.heroSlider} />
        </div>
      </section>

      <section className={"center inset jumbo"}>
        {/* <h2 className={"quote-start"}>{data.sections[1].title}</h2> */}
        <MobileView>
          <RenderOnMount>
            <div className={"slider"}>
              <Slider
                settings={{
                  mobileFirst: true,
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                }}
              >
                <div className={"testimonial card inset m"}>
                  <div className={"header"}>
                    <img
                      loading="lazy"
                      className={"dp"}
                      src={data.testimonials[0].dp.url}
                      alt={data.testimonials[0].name}
                    />
                    <h4>{data.testimonials[0].name}</h4>
                  </div>
                  <p>{data.testimonials[0].message}</p>
                  <img
                    loading="lazy"
                    className={"logo"}
                    src={data.testimonials[0].logo.url}
                    alt={data.testimonials[0].logo.alt}
                  />
                </div>
                <div className={"testimonial card inset m"}>
                  <div className={"header"}>
                    <img
                      loading="lazy"
                      className={"dp"}
                      src={data.testimonials[1].dp.url}
                      alt={data.testimonials[1].name}
                    />
                    <h4>{data.testimonials[1].name}</h4>
                  </div>
                  <p>{data.testimonials[1].message}</p>
                  <img
                    loading="lazy"
                    className={"logo"}
                    src={data.testimonials[1].logo.url}
                    alt={data.testimonials[0].logo.alt}
                  />
                </div>
                <div className={"testimonial card inset m"}>
                  <div className={"header"}>
                    <img
                      loading="lazy"
                      className={"dp"}
                      src={data.testimonials[2].dp.url}
                      alt={data.testimonials[2].name}
                    />
                    <h4>{data.testimonials[2].name}</h4>
                  </div>
                  <p>{data.testimonials[2].message}</p>
                  <img
                    loading="lazy"
                    className={"logo"}
                    src={data.testimonials[2].logo.url}
                    alt={data.testimonials[0].logo.alt}
                  />
                </div>
              </Slider>
            </div>
          </RenderOnMount>
        </MobileView>
        <BrowserView>
          <div className={"testimonials"}>
            {data.testimonials.map((testimony, index) => (
              <div key={index} className={"testimonial card inset m"}>
                <div className={"header"}>
                  <img
                    loading="lazy"
                    className={"dp"}
                    src={testimony.dp.url}
                    alt={testimony.name}
                  />
                  <h4>{testimony.name}</h4>
                </div>
                <p>{testimony.message}</p>
                <img
                  loading="lazy"
                  className={"logo"}
                  src={testimony.logo.url}
                  alt={testimony.logo.alt}
                />
              </div>
            ))}
          </div>
        </BrowserView>
      </section>

      <section className={"center inset jumbo"}>
        <h2 className={"likeh1"}>{data.sections[2].title}</h2>
        <ResponsiveImage
          image={data.sections[2].images[0]}
          className={"hero-image inset xl"}
        />
        <p>{data.sections[2].description}</p>
      </section>

      <section className={"left inset jumbojumbo squish"}>
        <div className={"content"}>
          <h2>{data.sections[3].title}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: data.sections[3].description }}
          ></p>
        </div>

        <ResponsiveImage
          image={data.sections[3].images[0]}
          className={"hero-image inset xl"}
        />
      </section>

      <section className={"right inset jumbojumbo squish"}>
        <div className={"content"}>
          <h2>{data.sections[4].title}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: data.sections[4].description }}
          ></p>
        </div>
        <ResponsiveImage
          image={data.sections[4].images[0]}
          className={"hero-image inset xl"}
        />
      </section>

      <section className={"center inset jumbo"}>
        <h2 className={"likeh1"}>{data.sections[5].title}</h2>
        <ResponsiveImage
          image={data.sections[5].images[0]}
          className={"hero-image inset xl"}
        />
        <p>{data.sections[5].description}</p>
      </section>

      <section className={"left inset jumbojumbo squish"}>
        <div className={"content"}>
          <h2>{data.sections[6].title}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: data.sections[6].description }}
          ></p>
        </div>
        <video
          playsInline={true}
          loop={true}
          autoPlay={true}
          muted={true}
          className={"hero-video inset xl"}
        >
          <source
            src={data.sections[6].videos[0].url}
            type="video/mp4"
          ></source>
        </video>
      </section>

      <section className={"right inset jumbojumbo squish"}>
        <div className={"content"}>
          <h2>{data.sections[7].title}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: data.sections[7].description }}
          ></p>
        </div>
        <ResponsiveImage
          image={data.sections[7].images[0]}
          className={"hero-image inset xl"}
        />
      </section>

      <section className={"center inset jumbo"}>
        <h2 className={"likeh1"}>{data.sections[8].title}</h2>
        <ResponsiveImage
          image={data.sections[8].images[0]}
          className={"hero-image inset xl"}
        />
        <p>{data.sections[8].description}</p>
      </section>

      <section className={"left inset jumbojumbo squish"}>
        <div className={"content"}>
          <h2>{data.sections[9].title}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: data.sections[9].description }}
          ></p>
        </div>
        <ResponsiveImage
          image={data.sections[9].images[0]}
          className={"hero-image inset xl"}
        />
      </section>

      <section className={"right inset jumbojumbo squish"}>
        <div className={"content"}>
          <h2>{data.sections[10].title}</h2>
          <p
            dangerouslySetInnerHTML={{ __html: data.sections[10].description }}
          ></p>
        </div>
        {/* <RenderOnMount>
          <MobileView>
            <video
              playsInline={true}
              loop={true}
              autoPlay={true}
              muted={true}
              className={"hero-video inset xl"}
            >
              <source
                src={data.sections[10].videos[0].mobileURL}
                type="video/mp4"
              ></source>
            </video>
          </MobileView>
          <BrowserView> */}
        <video
          playsInline={true}
          loop={true}
          autoPlay={true}
          muted={true}
          className={"hero-video inset xl"}
        >
          <source
            src={data.sections[10].videos[0].url}
            type="video/mp4"
          ></source>
        </video>
        {/* </BrowserView>
        </RenderOnMount> */}
      </section>

      <section className={"center inset jumbo integrations"}>
        <h2 className={"likeh1"}>{data.sections[11].title}</h2>
        <img
          alt={""}
          loading="lazy"
          src={data.sections[11].images[0].url}
          className={"inset xl"}
        />
        <p>{data.sections[11].description}</p>
        <div className={"logos"}>
          {data.integrations.map((integration, index) => (
            <img
              loading="lazy"
              alt="Rubrik's Cube"
              key={index}
              src={integration.logo.url}
              alt={integration.name}
            />
          ))}
        </div>
      </section>
      <section className={"subscribe-section"}>
        <h2 className={"likeh1"}>{data.sections[12].title}</h2>
        <form>
          <label>{data.sections[12].description}</label>
          <div className={"cta"}>
            <input
              id={"capture-cta"}
              placeholder={"Your email"}
              value={emailId}
              onInput={e => {
                setEmailId(e.target.value)
              }}
            ></input>
            <button
              id={"capture-button"}
              onClick={e => {
                e.preventDefault()
                if (emailId) {
                  registerInterest(emailId)
                  setEmailId("")
                  setIsToastVisible(true)
                }
              }}
            >
              {data.sections[12].cta.text}
            </button>
          </div>
        </form>
      </section>
    </Layout>
  )
}

export default IndexPage
